<template>
  <planejamento-acao-verba-variavel-form
    :somente-leitura="false"
    :novo="false"
    :edicao="true"
    />
</template>
<script>
// Componente utilizado para criar comportamento de auto redirecionamento
// criando efeito de refresh na rota
import PlanejamentoAcaoVerbaVariavelForm from '@/spa/planejamento-acao/form-verba-variavel/PlanejamentoAcaoVerbaVariavelForm';

export default {
  components: {
    PlanejamentoAcaoVerbaVariavelForm,
  },
};
</script>
